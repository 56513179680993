 <template>
    <div>
        <div class="header_inner clearFix">
            <div ref="header_icon"  class="header_icon"  @click="overlayShow"><img src="../assets/image/head_icon.png" alt=""></div>

            <div class="header_content clearFix" ref="myElement" >
                <router-link :to="{path:item.url}" v-for="(item, index) in list" :key="index">
                <div class="header_item" :class="[ index == activeTitle ? 'active' : '' ]"  :ref="setRef(index)">
                    <div class="item_content">
                        <div class="item_title">{{ item.name }}
                            <div :class="[ index == activeTitle ? 'underLine' : '' ]" ></div>
                        </div>
                        
                    </div>
                </div>
                </router-link>
                

            </div>
        </div>
        <div class="mask" v-if="maskshow" @click="overlayHidden">
            <div class="mask_list">
                <ul class="mask_content">
                    <router-link :to="{path:'/home'}"><li class="mask_item"><img src="../assets/image/icon1.png" alt=""><span>หน้าแรก</span></li></router-link>
                    <router-link :to="{path:'/Good'}"><li class="mask_item"><img src="../assets/image/icon2.png" alt=""><span>เติมเงิน</span></li></router-link>
                    <router-link :to="{path:'/group'}"><li class="mask_item"><img src="../assets/image/icon3.png" alt=""><span>เข้าร่วม</span></li></router-link>
                    <router-link :to="{path:'/CommonProblem'}"><li class="mask_item"><img src="../assets/image/icon4.png" alt=""><span>ศูนย์บริการลูกค้า</span></li></router-link>
                    <router-link :to="{path:'/UpdateLogs'}"><li class="mask_item"><img src="../assets/image/icon5.png" alt=""><span>ข่าวประกาศ</span></li></router-link>
                </ul>
            </div>
        </div>
    </div>
 </template>
<script>
export default {
    components: {

    },
    data() {
        return {
            maskshow : false,
            activeName:'1',
        }
    },
    created(){
        
    },
    mounted(){
        this.handleScroll()
    },
    methods:{
        overlayShow(){
            this.maskshow = true
        },
        overlayHidden(){
            this.maskshow = false
        },
        handleScroll(){
            //scroll偏移量
            if(this.list){
                const scrollWidth =this.$refs.myElementTtem[this.activeTitle].offsetLeft - this.$refs.myElement.offsetLeft - (((this.$refs.myElement.clientWidth - this.$refs.header_icon.clientWidth) - this.$refs.myElementTtem[this.activeTitle].clientWidth )/ 2)
                this.$refs.myElement.scrollTo(scrollWidth, 0);
            }
        },
        setRef() {
            return `myElementTtem`;
        },

    },
    props:['list',"activeTitle"]
}
</script>
 <style scoped lang="less">
 van-tabs{
    height: 100px;
 }
    .container{
      width: 690px;
      padding: 0px 30px;
    }
  .header_inner{
    height: 90px;
    width: 690px;
    line-height: 70px;
    .header_icon{
      width: 45px;
      height: 90px;
      float: left;
      img{
        width: 33px;
        height: 27px;
      }
    }
    .header_content{
        float: left;
        width: 640px;
        overflow: scroll;
        height: 90px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        scrollbar-width: none;
      .header_item{
        font-family: 'kt';
        white-space: nowrap;
        padding:0px 20px;
        font-size: 46px;
        color: #929DFF;
        .item_title{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        
      }
      .underLine{
        height: 6px;
        width: 80px;
        border-radius: 6px;
        background-color: #FFFFFF;
      }
      .active{
         color: #FFFFFF!important;
         font-size: 46px;
      }

    }
  }
  .mask{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2;
    .mask_list{
        width: 340px;
        height: 100%;
        background-color: #A6C9FF;
    }
    .mask_item{
        height: 88px;
        line-height: 88px;
        padding: 0px 25px;
        span{
            color: #FFFFFF;
            font-size: 30px;
        }
        img{
            margin-right: 20px;
            width: 55px;
            height: 55px;
            line-height: 88px;
            vertical-align: middle;
        }
    }
}
</style>