 <template>
    <van-tabbar v-model="active">
      <van-tabbar-item  to="/home">
        <span>หน้าแรก</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  to="/Good">
        <span>เติมเงิน</span>
        <template #icon="props">
          <img :src="props.active ? icon.active1 : icon.inactive1" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  to="/group">
        <span>เข้าร่วม</span>
        <template #icon="props">
          <img :src="props.active ? icon.active2 : icon.inactive2" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  to="/CommonProblem">
        <span>ติดต่อเรา</span>
        <template #icon="props">
          <img :src="props.active ? icon.active3 : icon.inactive3" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item  to="/UpdateLogs">
        <span>ประกาศ</span>
        <template #icon="props">
          <img :src="props.active ? icon.active4 : icon.inactive4" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
 </template>

<script>
export default {
    components: {

    },
    data() {
        return {
            active:this.activeIndex,
            icon: {
              active: require('@/assets/image/Vector_hover.png'),
              inactive: require('@/assets/image/Vector.png'),
              active1: require('@/assets/image/Vector2_hover.png'),
              inactive1: require('@/assets/image/Vector2.png'),
              active2: require('@/assets/image/Vector3_hover.png'),
              inactive2: require('@/assets/image/Vector3.png'),
              active3: require('@/assets/image/Vector4_hover.png'),
              inactive3: require('@/assets/image/Vector4.png'),
              active4: require('@/assets/image/Vector5_hover.png'),
              inactive4: require('@/assets/image/Vector5.png'),
            },
        }
    },
    props:['activeIndex']
}
</script>
 <style scoped  lang="less">
  
  .van-tabbar-item__icon img{
    width: 60px;
    height: 60px;
  }
  // van-tabbar{
  //   height: 80px;
  // }
  .van-tabbar{
    height: 120px;
  }
</style>